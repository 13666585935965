import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from "axios";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import 'element-plus/dist/index.css'

//axios.defaults.baseURL = "http://localhost:8000";
//axios.defaults.baseURL = "https://dev-mfa.inputorder.com"
// axios.defaults.baseURL = "https://dev03-mfa.inputorder.com"
axios.defaults.baseURL = "https://dev-v2-mfa.inputorder.com"
//axios.defaults.baseURL = "https://mfa.inputorder.com"
// axios.defaults.baseURL = "https://v2.mfa.inputorder.com"

const appInstance = createApp(App);
// appInstance.config.globalProperties.$hostname = 'http://localhost:8080';
appInstance.config.globalProperties.$hostname = "https://dev-dashboard.inputorder.com";
// appInstance.config.globalProperties.$hostname = "https://dashboard.inputorder.com";

appInstance.config.globalProperties.$appVersion = "Beta v.0.3.2";
appInstance.config.globalProperties.$appUpdatedAt = "11 Juni 2024";

appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");